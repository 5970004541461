import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import RouterWithPages from './RouterWithPages';
import { AuthProvider } from './context/AuthContext';
import { BundlesProvider } from './context/BundlesContext';
import { JobLoopProvider } from './context/JobLoopContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
        <BundlesProvider>
            <JobLoopProvider>
                <RouterWithPages  />
            </JobLoopProvider>
        </BundlesProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
