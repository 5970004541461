
import AppNav from "../../components/AppNav";
import { ReactNode } from "react";
import styles from './AppOutline.module.css';
import { useAuth } from "../../context/AuthContext";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";

type AppOutlineProps = {
    children: ReactNode;
};
const AppOutline:React.FC<AppOutlineProps> = (props) => {
    
    const auth = useAuth();

    if( !auth.signedIn ) {
        return (
            <div className={styles.root}>
                <div className={styles.auth_container}>
                    <Auth 
                        supabaseClient={auth.supabase} 
                        appearance={{ theme: ThemeSupa }} 
                        providers={[]}
                        />
                </div>
            </div>
        )
    }

    return (<div className={styles.root}>
        
        <AppNav />
        <main>
            {props.children}
        </main>
    </div>);
}
export default AppOutline;