// authContext.tsx

import React, { createContext, useContext, ReactNode, useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';


type JobLoopContextType = {
    active: boolean
};


const JOB_LOOP_INTERVAL_SECONDS = 30;


const JobLoopContext = createContext<JobLoopContextType | undefined>(undefined);

type AuthProviderProps = {
    children: ReactNode;
};
export const JobLoopProvider: React.FC<AuthProviderProps> = ({ children }) => {

    const [active, setActive] = useState(true);


    const auth = useAuth();

    const refresh = useCallback(async () => {
        const response = await auth.invokeEdge('job-queue-event-loop::POST', {
            exit_after_seconds: JOB_LOOP_INTERVAL_SECONDS
        });
        if( response.error ) {
            setActive(false);
            console.warn("JobLoopContext response error", response);
        } else {
            setActive(true);
        }
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            refresh();
        }, JOB_LOOP_INTERVAL_SECONDS*1000);

        refresh();

        return function unload() {
            clearInterval(timer);
        }
        
    }, [refresh])


    return (
        <JobLoopContext.Provider value={{ active }}>
            {children}
        </JobLoopContext.Provider>
    );
};

export const useJobLoop = () => {
    const context = useContext(JobLoopContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
