import AppOutline from "../../components/AppOutline";


const ViewMissingBundle:React.FC = () => {
    
    
    return (<AppOutline>
        
        <div>
            Oh no! Could not find that bundle. Please create a new one, or select another.
        </div>
        
    </AppOutline>);
}
export default ViewMissingBundle;


