import { NavLink, NavLinkProps } from "react-router-dom";
import { useBundles } from "../../context/BundlesContext";
import { useMemo } from "react";
import styles from './AppNav.module.css';
import { useAuth } from "../../context/AuthContext";




const AppNav: React.FC = () => {

    const auth = useAuth();

    const bundles = useBundles();

    const classNameChooser = useMemo(() => {
        const _classNameChooser: NavLinkProps['className'] = ({ isActive, isPending, isTransitioning }) =>
            [
                isPending ? "pending" : "",
                isActive ? "active" : "",
                isTransitioning ? "transitioning" : "",
            ].join(" ");
        return _classNameChooser
    }, [])
    
    const bundlesAlphabetically = useMemo(() => {
        const sBundles = [...bundles.bundles];
        sBundles.sort((a,b) => a.name.localeCompare(b.name));
        return sBundles;
    }, [bundles]);
    

    return (
        <nav className={styles.root}>
            <li>
                <NavLink
                    to='/new-bundle'
                    className={classNameChooser}
                >
                    New
                </NavLink>
            </li>
            {
                bundlesAlphabetically.map(bundle => (
                    <li key={bundle.id}>
                        <NavLink
                            to={`/view-bundle/${bundle.id}/${bundle.latestWorkflowID}`}
                            className={classNameChooser}
                        >
                            {bundle.name || 'Unnamed'}
                        </NavLink>
                    </li>
                ))
            }
            <li>
                <span 
                onClick={() => {
                    auth.signOut();
                }}
                className={styles.sign_out}
                >Sign Out</span>
            </li>
        </nav>
    );
}
export default AppNav;