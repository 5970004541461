
import './App.css';
import AppOutline from '../../components/AppOutline';


function App() {
  return (
    <AppOutline>
      
      Welcome!
    </AppOutline>
  );
}

export default App;
