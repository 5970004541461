import { useCallback, useEffect, useMemo, useState } from "react";
import { BundleWorkflowProduct } from "../..";
import JSZip from "jszip";
import { useAuth } from "../../../../context/AuthContext";
import { invokeEdge } from "../../../../EndpointMap";



const COMPRESSION_BUTTON_NAME:Record<CompressionStates,string> = {
    'none': 'Compress Images',
    'compressing': 'Compressing...',
    'complete': 'Compressed'
}

type CompressionStates = 'none' | 'compressing' | 'complete';

type ProductImagesProps = {
    bundleWorkflowProducts:BundleWorkflowProduct[],
    bundleWorkflowId: number,
    bundleId: number,
    workflowId: number
};
const ProductImages:React.FC<ProductImagesProps> = (props) => {
    const auth = useAuth();
    const [bundleWorkflowProducts, setBundleWorkflowProducts] = useState(props.bundleWorkflowProducts);
    const [compressionRequested, setCompressionRequested] = useState(false);
    const [exporting, setExporting] = useState(false);

    useEffect(() => {
        setBundleWorkflowProducts(props.bundleWorkflowProducts);
    }, [props.bundleWorkflowProducts]);

    
    const compressionState:CompressionStates = useMemo(() => {
        
        const progress = imageProgress(props.bundleWorkflowProducts);
        
        if( progress.started===0 && !compressionRequested ) {
            return 'none';
        } else if( progress.completed < progress.started ) {
            return 'compressing';
        } else {
            return 'complete';
        }
        
    }, [compressionRequested, props.bundleWorkflowProducts]);
    
    

    const onCompress = useCallback(async () => {
        setCompressionRequested(true);
        await invokeEdge('compress-images::POST', {
            bundle_workflow_id: props.bundleWorkflowId
        }, auth.supabase);
    }, []);

    const onExport = useCallback(async () => {
        setExporting(true);


        const images = bundleWorkflowProducts.flatMap(x => (x.image_details || []));
        
        const zip = new JSZip();
        
        
        for (const img of images) {
            
            const imageData = await auth.fetchEdge('view-compressed-image::POST', {
                bundle_workflow_products_image_id: img.bundle_workflow_products_image_id
            });
            if( !imageData.error ) {
                if( imageData.data && imageData.data instanceof Uint8Array ) {
                    
                

                    const blob = new Blob([imageData.data], { type: 'image/jpeg' });

                    const imageFileName = img.url.split('/').pop(); 
                    if( !imageFileName ) throw new Error("noop");
                    zip.file(imageFileName, blob);
                
                } else {
                    // Still processing
                }
            }
            
        }

        const content = await zip.generateAsync({type: 'blob'});
        const element = document.createElement('a');
        element.href = URL.createObjectURL(content);
        element.download = 'images.zip';
        document.body.appendChild(element);
        element.click();
        element.remove();

        setExporting(false);
        
    }, [props.bundleWorkflowId, bundleWorkflowProducts]);

    
    return (
        <div>
                <div>
                    <button disabled={compressionState!=='none'} onClick={onCompress}>{COMPRESSION_BUTTON_NAME[compressionState]}</button>
                    <button disabled={compressionState!=='complete' || exporting} onClick={onExport}>{exporting? 'Exporting...' : 'Export as ZIP'}</button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Original</th>
                            <th>Compressed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            bundleWorkflowProducts.map(x => x.image_details?.map(imgDetails => (
                                <tr key={imgDetails.bundle_workflow_products_image_id}>
                                    <td>{imgDetails.url}</td>
                                    <td>{imgDetails.compression_complete? 'Yes' : (imgDetails.compression_requested? 'Working...' : 'No')}</td>
                                </tr>
                            )))
                        }
                    </tbody>
                </table>
        </div>
    )
}

export default ProductImages;

function imageProgress(bundleWorkflowProducts: BundleWorkflowProduct[]): {started: number, completed: number} {

    let started:number = 0;
    let completed:number = 0;
    for( const bundleWorkflowProduct of bundleWorkflowProducts ) {
        if( bundleWorkflowProduct.image_details ) {
            for( const imgDetails of bundleWorkflowProduct.image_details ) {
                if( imgDetails.compression_requested ) started++;
                if( imgDetails.compression_complete ) completed++;
            }
        }
    }
    return {started, completed};
}


