import { useCallback, useState } from "react";
import AppOutline from "../../components/AppOutline";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import styles from './NewBundle.module.css';
import { useBundles } from "../../context/BundlesContext";
import { CSVBoxButton } from '@csvbox/react'
import { mkConfig as mkConfigCsv, generateCsv, asString } from "export-to-csv";

const NewBundle:React.FC = () => {

    const [columns/*, setColumns*/] = useState([`title`, `brand`, `url`, `image_urls`,  `dimensions`, `colour`,'description','fact_sheet']);
    const [bundleName, setBundleName] = useState<string>('');
    const [productMode,setProductMode] = useState<'upload' | 'manual'>('upload');
    const [csv, setCsv] = useState<string>('');
    const [keywordsCsv, setKeywordsCsv] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [businessType, setBusinessType] = useState<string>('');
    const [toneOfVoice, setToneOfVoice] = useState<string>('');
    const [creating, setCreating] = useState(false);
    const auth = useAuth();
    const bundles = useBundles();
    const navigate = useNavigate();
    
    const create = useCallback(() => {
        (async () => {
            
            
            if( !bundleName || !csv ) {
                alert("Please enter the bundle name, and some product info.");
                return;
            }
            setCreating(true);

            // Send to server
            const response = await auth.invokeEdge('new-bundle::POST', {
                bundle_name: bundleName,
                columns,
                raw_csv: csv,
                custom_fields: {
                    keywords: keywordsCsv,
                    url_template: url, 
                    business_type: businessType,
                    tone_of_voice: toneOfVoice
                }
            });
            if( response.error ) {
                alert(`Something went wrong.\nMake sure your CSV exactly matches the example given.\n\n(Hint: ${response.error.message ?? response.error.type})`);
                setCreating(false);
            } else if( response.data ) {
                const {bundle_id/*, bundle_workflow_id*/, workflow_id} = response.data;

                // Redirect nav 
                await bundles.refreshBundles();
                navigate(`/view-bundle/${bundle_id}/${workflow_id}`)
            }
        })();
        
    }, [csv, productMode, keywordsCsv, bundleName, bundles, url, businessType, toneOfVoice]);

    const createPreFab = useCallback(async (set: 'ashgrove' | 'tof' | 'BigMatMalta') => {
        const bundleName: string = prompt("Bundle name?", set) ?? '';
        //let columns: string[] = [];
        let csv: string = '';
        let keywordsCsv: string = '';
        let url: string = '';
        let businessType: string = '';
        let toneOfVoice:string = `
Write like a human, in a conversational tone. 
Avoid emojis
Avoid exclamations
            `.trim()

        if( set==='ashgrove' ) {
            //columns = [`title`, `brand`, `url`, `image_urls`,  `dimensions`, `colour`,'description','fact_sheet'];
            url = "https://www.ashgrovefurnishings.com/{{keywords}}-{{variants}}.html";
            keywordsCsv = "Madrid";
            csv = `
Alegro Mirrored Sliding Wardrobe in Alpine White by Rauch,Rauch,https://www.ashgrovefurnishings.com/alegro-2-door-mirrored-sliding-wardrobe-by-rauch-w271-alpine-white.html, https://www.ashgrovefurnishings.com/media/catalog/product/cache/127df9f9c0abe9384bdddfb12d102a62/a/l/alegro_wardrobe_alpine_white_8.jpg, W181 x D62 x H210 cm, Alpine White,,
Alegro Mirrored Sliding Wardrobe in Alpine White and Metalic Grey by Rauch,Rauch,https://www.ashgrovefurnishings.com/alegro-2-door-mirrored-sliding-wardrobe-by-rauch-w181-metallic-grey-alpine-white.html,https://www.ashgrovefurnishings.com/media/catalog/product/cache/127df9f9c0abe9384bdddfb12d102a62/a/l/alegro_wardrobe_metallic_grey_alpine_white_3.jpg, W271 x D62 x H210 cm, Metallic Grey; Alpine White,,
Madrid Oak & Black Lamp Table by Vida Living,,https://www.ashgrovefurnishings.com/madrid-oak-and-black-occasional-furniture-range-by-vida-living-lamp-table.html,https://www.ashgrovefurnishings.com/media/catalog/product/cache/127df9f9c0abe9384bdddfb12d102a62/m/a/madrid_oak_and_black_lamp_table_1.jpg,,,
Madrid Oak & Black Large TV Unit by Vida Living,,https://www.ashgrovefurnishings.com/madrid-oak-and-black-occasional-furniture-range-by-vida-living-large-tv-unit.html,https://www.ashgrovefurnishings.com/media/catalog/product/cache/127df9f9c0abe9384bdddfb12d102a62/m/a/madrid_oak_and_black_tv_1200_with_doors_front_1.jpg,,,
Madrid Oak & Black Small TV Unit by Vida Living,,https://www.ashgrovefurnishings.com/madrid-missing-url-parts.html,,,,
            `.trim()
        } else if( set==='BigMatMalta' ) {
            //columns = [`title`, `brand`, `url`, `image_urls`,  `dimensions`, `colour`,'description','fact_sheet'];
            url = "https://www.bigmatmalta.com/product/{{keywords}}-{{variants}}";
            keywordsCsv = "";
            csv = `
INDUSTRIAL CEILING FAN WHITE Φ140 80W,Eurolamp,,,140cm diameter,white,,"Supply Voltage (V):220-240V.Nominal Power (W):80W.Operating Frequency (Hz):50/60Hz Product Net Weight (kg):4 Product Net Weight (kg):4,Product Gross Weight (kg):5.Remote Control:ncluded Wall-Mounted Switch.Speeds:5.Height:52.5cm.Diameter:Φ140cm.RPM:312rpm.Noise Level (dB):60dB.Product Color: White.Product Material:Metallic & Plastic.Blades Material:Metallic.Blades Number:3.Base Type:Ceiling.Area Coverage (m2):30-40.Connection Type:Wire.Packing (pcs):1.Guarantee Eurolamp:2 Years.Certifications:Yes"
INDUSTRIAL CEILING FAN Black Φ140 80W,Eurolamp,,,140cm diameter,black,,"Supply Voltage (V):220-240V.Nominal Power (W):80W.Operating Frequency (Hz):50/60Hz Product Net Weight (kg):4 Product Net Weight (kg):4,Product Gross Weight (kg):5.Remote Control:Included Wall-Mounted Switch.Speeds:5.Height:52.5cm.Diameter:Φ140cm.RPM:312rpm.Noise Level (dB):60dB.Product Color:Λευκό/ Black.Product Material:Metallic & Plastic.Blades Material:Metallic.Blades Number:3.Base Type: Ceiling.Area Coverage (m2):30-40.Connection Type: Wire.Packing (pcs):1.Guarantee Eurolamp:2 Years.Certifications:Yes"
"DECORATIVE CEILING FAN LIGHT WOODEN COLOR, 4 BLADES, WITH CONTROL Φ122 58W DC MOTOR",Eurolamp,,,122cm diameter,wood,"Reverse Blades Rotation, ideal for Summer and Winter. Silent, Smaller & Lighter Motor. Up to 50% less consumption - ECO Friendly","Supply Voltage (V):220-240,Nominal Power (W):58,Operating Frequency (Hz):50-60Hz,Pro,Blades Material:Plywoodduct Net Weight (kg):4,Product Gross Weight (kg):5,Remote Control:Yes,Operating Hours of the Timer:1h-2h-4h-8h,Speeds:5,Height:38,Diameter:Φ122,RPM:208,Noise Level (dB):46,Product Color:White & Brown,Product Material: Plastic, Plywood & Metallic,Blades Number:4,Area Coverage (m2):15-20,Packing (pcs):1,Guarantee Eurolamp: 3 years,Certifications:Yes"
"DECORATIVE FAN BLACK, 4 BLADES, WITH CONTROL Φ122 58W DC MOTOR",Eurolamp,,,122cm diameter,black,"Reverse Blades Rotation, ideal for Summer and Winter. Silent, Smaller & Lighter Motor. Up to 50% less consumption - ECO Friendly","Supply Voltage (V):220-240V,Nominal Power (W):58W,Operating Frequency (Hz):50-60Hz,Product Net Weight (kg):4,000kg,Product Gross Weight (kg):5,000kg,Remote Control:Yes,Timer:Yes,Operating Hours of the Timer:1h-2h-4h-8h,Speeds:5,Height:38cm,Diameter:Φ122cm,RPM:208rpm,Noise Level (dB):46dB,Product Color: Black,Product Material:Plastic, Plywood & Metallic,Blades Material:Plywood,Blades Number:4,Area Coverage (m2):15-20m2,Packing (pcs):1,Guarantee Eurolamp:3 years,Certifications:Yes"
"DECORATIVE FAN WITH 1 LIGHT E27 NICKEL MAT WITH CONTROL Φ122 70W",Eurolamp,,,122cm diameter,nickel mat,"Reverse Blades Rotation, ideal for Summer and Winter","Supply Voltage (V):220-240,Nominal Power (W):70,Operating Frequency (Hz):50/60,Product Net Weight (kg):6,2,Product Gross Weight (kg):6,8,Remote Control:Yes,Timer:Yes,Operating Hours of the Timer:8,Speeds:3,Height:45,Diameter:Φ122,RPM:180,Noise Level (dB):40,Product Material Metallic,Blades Material:MDF,Blades Number:5,Base Type:Ceiling,Air Volume m3/h:100-120m3/h,Area Coverage (m2):10-20m²,Connection Type:Wire,Packing (pcs):1,Guarantee Eurolamp:2 Years,Certifications: Yes,Lights:1xE27"
"DECORATIVE FAN WITH 1 LIGHT E27 BROWN GOLD Φ132 70W WITH REMOTE CONTROL",Eurolamp,,,132cm diameter,brown gold,"Reverse Blades Rotation, ideal for Summer and Winter","Supply Voltage (V):220-240,Nominal Power (W):70,Operating Frequency (Hz):50,Product Net Weight (kg):4.7,Product Gross Weight (kg):5.2,Remote Control:Yes,Speeds:3,Height:42,Diameter:Φ132,RPM:200,Noise Level (dB):40dB,Product Color:Καφέ - Χρυσό/ Brown - Gold,Product Material:Metallic,Blades Material:MDF,Blades Number:4,Base Type:Ceiling,Air Volume m3/h:100-120m3/h,Area Coverage (m2):15m²,Packing (pcs):1,Guarantee Eurolamp:2 years,Certifications: Yes,Lights:1xE27"
            `.trim()
            /*
            csv = `
LANDMANN KAMADO CHARCOAL GRILL 40CM,https://bigmatmalta.com/product/landmann-kamado-charcoal-grill-40cm/,LANDMANN,,grey,
WING DECKCHAIR,https://bigmatmalta.com/product/wing-deckchair/,HiGold,,Grey,
HYDRO FORCE KAYAK - 2 pers,https://bigmatmalta.com/product/hydro-force-inflatable-kayak/,Bestway,331X81CM ,blue,
ACTIVE GREEN EDITION UMBRELLA 210CM ANTHRACITE,https://bigmatmalta.com/product/anthracite-patio-green-edition-umbrella/,Doppler,210 CM,Black,
PERGOLA OPTIVA 319X319 ANTH,https://bigmatmalta.com/product/pergola-optiva-319x319-anth/,,319X319 cm,,
Infinite Xtra Spa,https://bigmatmalta.com/product/infinite-spa-spa-system-xtra-spa-800-round/,,180×65 cm,,"Includes: bottom cover; pool cover; storage bag & repar kit. Fits up to 4 people>?180x65 cm"
Swing chair cover 205x130xH130/55 Anthracite,https://bigmatmalta.com/product/swing-chair-cover-205x130xh130-55-anthracite/,Aerocover,205x130xH130 cm ,,
Ergo Folding Shovel,https://bigmatmalta.com/product/folding-shovel-ergo/,Cellfast,,,"Durable tool steel. Includes carrying case. Length: 58.5 cm."
            `.trim()
            */
            businessType = `
You sell home improvement products and industrial tools.
            `.trim()
            /*
            toneOfVoice = `
Write like a human, in a conversational tone. 
Avoid emojis.
Avoid exclamations. 
Do not use hyperbole... you must keep your sales promises practical and grounded. Just share the useful facts. 
Use small words with a practical tone.
If the product mentions "industry" or "industrial", it's intended to be used in factories and warehouses, and you must only describe it in a work context.
            `.trim()
            */
           // Avoid the word cool... replace it with something creative instead.

            toneOfVoice = `
Strictly write like a blue collar working man, in a conversational tone. 
Do not use the word 'cool' (replace it some creative alternative), and do not use fancy words like 'elegance' (remember that this is for trades people).
Avoid emojis.
Avoid exclamations. 
Do not use hyperbole... you must keep your sales promises practical and grounded. Just share the useful facts. 
Use small words with a practical tone.
If the product mentions "industry" or "industrial", it's intended to be used in factories and warehouses, and you must only describe it in a work context.
            `.trim()
        } else if( set==='tof' ) {
            url = "https://tons-of-fun-store.myshopify.com/products/{{keywords}}-{{variants}}";
            keywordsCsv = "";
            /*
            columns = [`title`, `brand`, `url`, `image_urls`, `product_category`, `material_finish`, `item_count`, `media_category`, `media_character`, `dimensions`, `colour`, `religion`, `age_milestone`, `occassion`, `product_theme`, `printed_message_text`, `seasonal_occassion`, `party_use_case`, `suited_to_gender`, `printed_message_letter`, `shape`, `printed_message_number`];
            csv = `
"You’re Awesome Balloon - 18"" Foil",Grabo,https://tons-of-fun-store.myshopify.com/products/youre-awesome-balloon-18-foil,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/86832H-Youre-Awesome.jpg?v=1698621283,,,,,,,,,,,,You're Awesome,,,,,,
"You’re Awesome Balloon - 18"" Foil",Kaleidoscope,https://tons-of-fun-store.myshopify.com/products/youre-awesome-balloon-18-foil-2,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/eyJidWNrZXQiOiJhcGFjZ3JvdXAud2ViIiwia2V5IjoiaW1nXC8xMjcwNTU1X08uanBlZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6ODc4LCJoZWlnaHQiOjkwMCwiZml0IjoiZmlsbCIsImJhY2tncm91bmQiOnsic.jpg?v=1698621283,,,,,,,,,,,,You're Awesome,,,,,,
"Thank You Balloon - 18"" Foil",Kaleidoscope,https://tons-of-fun-store.myshopify.com/products/kaleidoscope-kaleidoscope-thank-you-foil-balloon-19494-18,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/Thank-You-Stars-1.jpg?v=1698620290,,,,,,,,,,,Patterns & Prints,Thank You,,,,,,
"Thank You Colourful Flowers Balloon - 18"" Foil",Anagram,https://tons-of-fun-store.myshopify.com/products/anagram-thank-you-colourful-flowers-standard-1995401,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/thankyoucolourfulflowers18s40-1.jpg?v=1698619516,,,,,,,,,,,"Flowers, Bugs & Butterflies",Thank You,,,,,,
"Pirate Skull Supershape Balloon - 26"" Foil",Anagram,https://tons-of-fun-store.myshopify.com/products/anagram-street-treats-pirate-skull-shape-2477102,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/Pirate-skull-2477102-1.jpg?v=1698617371,,,,,,,,,,,Pirates & Princesses,,,,,,,
"Ballerina Slippers Supershape Balloon - 35"" Foil",Qualatex,https://tons-of-fun-store.myshopify.com/products/ballerina-slippers-supershape-balloon-35-foil,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/26994_26996B.png?v=1698617423,,,,,,,,,,,Pirates & Princesses,,,,,,,
Silver No. 24 Double Faced Satin - (3mm x 50m),Oaktree,https://tons-of-fun-store.myshopify.com/products/oaktree-eleganza-double-faced-satin-3mm-x-50m-silver-no-24-452495,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/bg452495_3mm_Silver-1.jpg?v=1698616080,Satin,,,,,,,,,,,,,,,,,
Fuchsia No.28 Double Faced Satin - (6mm x 20m),Oaktree,https://tons-of-fun-store.myshopify.com/products/oaktree-eleganza-double-faced-satin-6mm-x-20m-fuchsia-no-28-452815,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/bg452815_6mm_Fuchsia-1-2-1_c5f4d459-4ebc-4425-908a-edeb8177e3d6.jpg?v=1698616081,Satin,,,,,,,,,,,,,,,,,
Lime Green No.14 Double Faced Satin - (6mm x 20m),Oaktree,https://tons-of-fun-store.myshopify.com/products/oaktree-eleganza-double-faced-satin-6mm-x-20m-lime-green-no-14-452877,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/bg452877_6mm_Lime-Green-1.jpg?v=1698616085,Satin,,,,,,,,,,,,,,,,,
"Mickey Mouse Red & Pale Blue Balloons - 36"" Latex (2ct)",Qualatex,https://tons-of-fun-store.myshopify.com/products/qualatex-mickey-mouse-red-pale-blue-3-foot-latex-2-ct-49576,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/mickey-mouse-3-red-pale-blue-2ct-1.jpg?v=1695999261,,,2,Disney,Mickey Mouse,"36""",Blue,,,,,,,,,,,
"Minnie Mouse Pink & Wild Berry Balloons - 36"" Latex (2ct)",Qualatex,https://tons-of-fun-store.myshopify.com/products/qualatex-minnie-mouse-pink-wild-berry-3-foot-latex-2-ct-49577,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/minnie-mouse-3-pink-wild-berry-2ct-1.jpg?v=1695999266,,,2,Disney,Minnie Mouse,"36""",Pink,,,,,,,,,,,
White Gloss Sign Vinyl - 30cm x 5m,Superior,https://tons-of-fun-store.myshopify.com/products/white-gloss-vinyl-305mm-x-5m,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/White-Vinyl-1_cf8934b7-58f3-4923-ac70-92597656476f.jpg?v=1698621995,,Gloss,,,,,,,,,,,,,,,,
White Matt Sign Vinyl - 30cm x 5m,Superior,https://tons-of-fun-store.myshopify.com/products/white-matt-vinyl-ritrama-305mm-x-5m,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/White-Vinyl-Matt-1_4c18b29e-55f9-4858-a9ac-bd9ce72fb16f.jpg?v=1698621996,,Matt,,,,,,,,,,,,,,,,
White Pearl Glitter Heat Transfer Vinyl - 50cm x 1m,Poli Tape,https://tons-of-fun-store.myshopify.com/products/white-pearl-glitter-heat-transfer-vinyl-1m-x-500mm,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/444-White.png?v=1698621999,,Glitter HTV,,,,,,,,,,,,,,,,
White Reflex Heat Transfer Vinyl - 51cm x 1m,VinylEfx,https://tons-of-fun-store.myshopify.com/products/white-reflex-vinyl-510mm-x-1m,,,Reflex HTV,,,,,,,,,,,,,,,,
4.9m L.E.D. Rope Lights for Balloon Arch - (1ct),Tons of Fun Store,https://tons-of-fun-store.myshopify.com/products/apac-accessories-l-e-d-rope-lights-for-balloon-arch-4-9m,,,,,,,,,,,,,,,,,,,
Arch in Heart Shape - 2.6w,Borosino,https://tons-of-fun-store.myshopify.com/products/arch-in-heart-shape-1ct-2-2h-x-2-6w,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/b456-arche-coeur.jpg?v=1698615815,,,,,,,,,,,,,,,,,,
Balloon Arch outdoor - 3m x 3m,Borosino,https://tons-of-fun-store.myshopify.com/products/balloon-arch-outdoor-3m-x-3m-wide,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/b429a-archemetal_557de89c-2ab0-453a-9c89-ca5160b3ce75.jpg?v=1698615825,,,,,,,,,,,,,,,,,,
Balloon Column - Plastic 1.6m,Borosino,https://tons-of-fun-store.myshopify.com/products/balloon-column-plastic-1-6m,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/b805g1.jpg?v=1698615830,,,,,,,,,,,,,,,,,,
Black Balloon Arch Line - 274m,Borosino,https://tons-of-fun-store.myshopify.com/products/black-balloon-arch-line-274m-1-pc,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/65357-bor_b907b-1-3-1500.jpg?v=1698615843,,,,,,,,,,,,,,,,,,
Black Column & Base Set - 1.7m,Ikea,https://tons-of-fun-store.myshopify.com/products/black-column-base-set-1-7m,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/display-column-tagarp.webp?v=1698615847,,,,,,,,,,,,,,,,,,
            `.trim()
            */
            //columns = [`title`, `brand`, `url`, `image_urls`,  `dimensions`, `colour`,'description','fact_sheet'];
            csv = `
"You’re Awesome Balloon - 18"" Foil",Grabo,https://tons-of-fun-store.myshopify.com/products/youre-awesome-balloon-18-foil,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/86832H-Youre-Awesome.jpg?v=1698621283,,,,
"You’re Awesome Balloon - 18"" Foil",Kaleidoscope,https://tons-of-fun-store.myshopify.com/products/youre-awesome-balloon-18-foil-2,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/eyJidWNrZXQiOiJhcGFjZ3JvdXAud2ViIiwia2V5IjoiaW1nXC8xMjcwNTU1X08uanBlZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6ODc4LCJoZWlnaHQiOjkwMCwiZml0IjoiZmlsbCIsImJhY2tncm91bmQiOnsic.jpg?v=1698621283,,,,
"Mickey Mouse Red & Pale Blue Balloons - 36"" Latex (2ct)",Qualatex,https://tons-of-fun-store.myshopify.com/products/qualatex-mickey-mouse-red-pale-blue-3-foot-latex-2-ct-49576,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/mickey-mouse-3-red-pale-blue-2ct-1.jpg?v=1695999261,"36""",Blue,,
"Minnie Mouse Pink & Wild Berry Balloons - 36"" Latex (2ct)",Qualatex,https://tons-of-fun-store.myshopify.com/products/qualatex-minnie-mouse-pink-wild-berry-3-foot-latex-2-ct-49577,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/minnie-mouse-3-pink-wild-berry-2ct-1.jpg?v=1695999266,"36""",Pink,,
White Pearl Glitter Heat Transfer Vinyl - 50cm x 1m,Poli Tape,https://tons-of-fun-store.myshopify.com/products/white-pearl-glitter-heat-transfer-vinyl-1m-x-500mm,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/444-White.png?v=1698621999,,,,
"Thank You Balloon - 18"" Foil",Kaleidoscope,https://tons-of-fun-store.myshopify.com/products/kaleidoscope-kaleidoscope-thank-you-foil-balloon-19494-18,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/Thank-You-Stars-1.jpg?v=1698620290,,,,
"Thank You Colourful Flowers Balloon - 18"" Foil",Anagram,https://tons-of-fun-store.myshopify.com/products/anagram-thank-you-colourful-flowers-standard-1995401,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/thankyoucolourfulflowers18s40-1.jpg?v=1698619516,,,,
"Pirate Skull Supershape Balloon - 26"" Foil",Anagram,https://tons-of-fun-store.myshopify.com/products/anagram-street-treats-pirate-skull-shape-2477102,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/Pirate-skull-2477102-1.jpg?v=1698617371,,,,
"Ballerina Slippers Supershape Balloon - 35"" Foil",Qualatex,https://tons-of-fun-store.myshopify.com/products/ballerina-slippers-supershape-balloon-35-foil,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/26994_26996B.png?v=1698617423,,,,
Silver No. 24 Double Faced Satin - (3mm x 50m),Oaktree,https://tons-of-fun-store.myshopify.com/products/oaktree-eleganza-double-faced-satin-3mm-x-50m-silver-no-24-452495,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/bg452495_3mm_Silver-1.jpg?v=1698616080,Satin,,,,
Fuchsia No.28 Double Faced Satin - (6mm x 20m),Oaktree,https://tons-of-fun-store.myshopify.com/products/oaktree-eleganza-double-faced-satin-6mm-x-20m-fuchsia-no-28-452815,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/bg452815_6mm_Fuchsia-1-2-1_c5f4d459-4ebc-4425-908a-edeb8177e3d6.jpg?v=1698616081,,,,
Lime Green No.14 Double Faced Satin - (6mm x 20m),Oaktree,https://tons-of-fun-store.myshopify.com/products/oaktree-eleganza-double-faced-satin-6mm-x-20m-lime-green-no-14-452877,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/bg452877_6mm_Lime-Green-1.jpg?v=1698616085,,,,
White Gloss Sign Vinyl - 30cm x 5m,Superior,https://tons-of-fun-store.myshopify.com/products/white-gloss-vinyl-305mm-x-5m,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/White-Vinyl-1_cf8934b7-58f3-4923-ac70-92597656476f.jpg?v=1698621995,,,,
White Matt Sign Vinyl - 30cm x 5m,Superior,https://tons-of-fun-store.myshopify.com/products/white-matt-vinyl-ritrama-305mm-x-5m,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/White-Vinyl-Matt-1_4c18b29e-55f9-4858-a9ac-bd9ce72fb16f.jpg?v=1698621996,,,,
White Pearl Glitter Heat Transfer Vinyl - 50cm x 1m,Poli Tape,https://tons-of-fun-store.myshopify.com/products/white-pearl-glitter-heat-transfer-vinyl-1m-x-500mm,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/444-White.png?v=1698621999,,,,
White Reflex Heat Transfer Vinyl - 51cm x 1m,VinylEfx,https://tons-of-fun-store.myshopify.com/products/white-reflex-vinyl-510mm-x-1m,,,,
4.9m L.E.D. Rope Lights for Balloon Arch - (1ct),Tons of Fun Store,https://tons-of-fun-store.myshopify.com/products/apac-accessories-l-e-d-rope-lights-for-balloon-arch-4-9m,,,,
Arch in Heart Shape - 2.6w,Borosino,https://tons-of-fun-store.myshopify.com/products/arch-in-heart-shape-1ct-2-2h-x-2-6w,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/b456-arche-coeur.jpg?v=1698615815,,,,
Balloon Arch outdoor - 3m x 3m,Borosino,https://tons-of-fun-store.myshopify.com/products/balloon-arch-outdoor-3m-x-3m-wide,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/b429a-archemetal_557de89c-2ab0-453a-9c89-ca5160b3ce75.jpg?v=1698615825,,,,
Balloon Column - Plastic 1.6m,Borosino,https://tons-of-fun-store.myshopify.com/products/balloon-column-plastic-1-6m,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/b805g1.jpg?v=1698615830,,,,
Black Balloon Arch Line - 274m,Borosino,https://tons-of-fun-store.myshopify.com/products/black-balloon-arch-line-274m-1-pc,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/65357-bor_b907b-1-3-1500.jpg?v=1698615843,,,,
Black Column & Base Set - 1.7m,Ikea,https://tons-of-fun-store.myshopify.com/products/black-column-base-set-1-7m,https://cdn.shopify.com/s/files/1/0740/2232/2454/files/display-column-tagarp.webp?v=1698615847,,,,
            `.trim()
            businessType = `
You sell cheap toys and gifts for children. Parents are your target market. 
            `.trim()
            toneOfVoice = `
Write like a human, in a conversational tone. 
Avoid emojis
Avoid exclamations
Stay realistic in your promises - and avoid hyperbole - because you're selling cheap and cheerful toys
Use small words and a joyful tone 
Be funny and witty, joking with the nature of the product 
            `.trim()
        }

        setBundleName(bundleName);
        //setColumns(columns);
        setUrl(url);
        setKeywordsCsv(keywordsCsv);
        setCsv(csv);
        setBusinessType(businessType);
        setToneOfVoice(toneOfVoice);

        /*
        setCreating(true);

        // Send to server
        const response = await auth.invokeEdge('new-bundle::POST', {
            bundle_name: bundleName,
            columns,
            raw_csv: csv,
            custom_fields: {
                keywords: keywordsCsv,
                url_template: url
            }
        });
        if( response.error ) {
            alert("Something went wrong.\n\nMake sure your CSV exactly matches the example given, and check you didn't use an extra commas.");
            setCreating(false);
        } else if( response.data ) {
            const {bundle_id, bundle_workflow_id, workflow_id} = response.data;

            // Redirect nav 
            await bundles.refreshBundles();
            navigate(`/view-bundle/${bundle_id}/${workflow_id}`)
        }
        */
    }, []);

    return (
        <AppOutline>

            <div className={styles.input_container}>
                <label>Bundle Name</label>
                <input type='text' value={bundleName} onChange={e => setBundleName(e.target.value)} placeholder="Bundle Name" />
            </div>
            
            <div className={styles.input_container}>
                <div className={styles.label_container}>
                    <label>Product List</label>
                    {productMode==='upload'?
                    (
                        <span onClick={() => setProductMode('manual')}>Switch to Manual Input</span>
                    )
                    :
                    (
                        <span onClick={() => setProductMode('upload')}>Switch to the Uploader</span>
                    )
                    }
                </div>
                {productMode==='upload'?
                (
                    <div>
                        {csv?
                        (<div>
                            Import OK
                        </div>)
                        : null }
                        <CSVBoxButton
                            licenseKey="eSN4ZIERTvwZTyrfLNRNmfsTWrHf9A"
                            user={{
                                user_id: auth.user?.email
                            }}
                            onImport={(result, data) => {
                                
                                const csvConfig = mkConfigCsv({ columnHeaders: columns, showColumnHeaders: false });

                                
                                if(result){
                                    let csvString = asString(generateCsv(csvConfig)(data.rows));
                                    if( csvString.indexOf(`"${columns[0]}",`)===0 ) {
                                        csvString = csvString.replace(/^.+\r\n/, '');
                                    }
                                    setCsv(csvString);
                                    console.log(data.row_success + " rows uploaded", {result,data,csvString});
                                } else {
                                    console.log("fail");
                                }
                            }}
                            >
                            {csv? "Import again" : "Import"}
                        </CSVBoxButton>
                    </div>
                )
                :
                (
                    <div>
                        <textarea className={styles.csvbox} value={csv} onChange={e => setCsv(e.target.value)} placeholder={columns.join(', ')}/>
                        <div>
                            Put each product on a new line in this format: {columns.join(', ')}
                        </div>
                        <div>
                            For image_urls, separate multiple ones with |
                        </div>
                    </div>
                )
                }
                
                
                
            </div>

            <div className={styles.input_container}>
                <label>Bundle&apos;s Primary Keywords</label>
                <textarea className={styles.csvbox} value={keywordsCsv} onChange={e => setKeywordsCsv(e.target.value)} placeholder='Keywords'/>
            </div>
            
            <div className={styles.input_container}>
                <label>URL Template</label>
                <input type='text'  value={url} onChange={e => setUrl(e.target.value)}/>

                <div>
                    {`E.g. https://www.yourdomain.com/{{keywords}}-{{variants}}.html`}
                </div>
            </div>
            
            <div className={styles.input_container}>
                <label>Type of Business:</label>
                <textarea  value={businessType} onChange={e => setBusinessType(e.target.value)}/>

                <div>
                    Describe the nature/category of the products, and who you sell to. 
                    Put each instruction on a new line.
                </div>
            </div>




            <div className={styles.input_container}>
                <label>Tone of Voice</label>
                <textarea  value={toneOfVoice} onChange={e => setToneOfVoice(e.target.value)}/>

                <div>
                    Describe how you want the long-form copy to be styled. 
                </div>
            </div>


            <button disabled={csv.length===0 || creating} onClick={create}>{creating? 'Creating...' : 'Create'}</button>

            <h3>Pre-Fill</h3>
            <div>
                <button onClick={() => createPreFab('ashgrove')}>Ashgrove</button>
                <button onClick={() => createPreFab('BigMatMalta')}>Big Mat Malta</button>
                <button onClick={() => createPreFab('tof')}>Tons of Fun</button>
            </div>
            
        </AppOutline>
        
    );
}
export default NewBundle;


