// authContext.tsx

import React, { createContext, useContext, ReactNode, useState, useEffect, useMemo, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { EndpointTypesMap } from '../EndpointMap';


type BundlesContextType = {
    bundles: BundleWithLatestWorkflow[],
    workflows: Workflow[],
    refreshBundles: () => Promise<void>
};

type BundleOverview = EndpointTypesMap['list-bundles::POST']['response']['bundles'][number];
type Workflow = EndpointTypesMap['list-workflows::POST']['response']['workflows'][number];

type BundleWithLatestWorkflow = BundleOverview & {
    latestWorkflowID:number | undefined
}

const BundlesContext = createContext<BundlesContextType | undefined>(undefined);

type AuthProviderProps = {
    children: ReactNode;
};
export const BundlesProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [bundlesPre1, setBundlesPre1] = useState<BundleOverview[]>([]);
    const [workflows, setWorkflows] = useState<Workflow[]>([]);

    const auth = useAuth();


    const refreshWorkflows = useCallback(async () => {
        const response = await auth.invokeEdge('list-workflows::POST', undefined);
        if( response.data ) {
            setWorkflows(response.data.workflows);
        } else {
            console.warn("ListWorkflows bad response", response);
        }
    }, []);

    const refreshBundles = useCallback(async () => {
        await refreshWorkflows();

        const response = await auth.invokeEdge('list-bundles::POST', undefined);
        if( response.data ) {
            setBundlesPre1(response.data.bundles);
        } else {
            console.warn("ListBundles bad response", response);
        }
    }, []);

    useEffect(() => {
        refreshBundles();
    }, [refreshBundles])


    const bundles = useMemo(() => {
        if( !Array.isArray(bundlesPre1) ) return [];

        // TODO #DATA_STITCH
        return bundlesPre1.map(bundlePre1 => {
            const bundle:BundleWithLatestWorkflow = {...bundlePre1, latestWorkflowID: undefined};
            if( bundle.workflows.length===0 ) throw new Error("Bundles should always be created with at least one bundle_workflow.");
            bundle.latestWorkflowID = bundle.workflows[bundle.workflows.length-1].workflow_id;
            return bundle;
        })
    }, [bundlesPre1]);

    return (
        <BundlesContext.Provider value={{ bundles, refreshBundles, workflows }}>
            {children}
        </BundlesContext.Provider>
    );
};

export const useBundles = () => {
    const context = useContext(BundlesContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
