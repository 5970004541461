import { LoaderFunction, useLoaderData } from "react-router-dom";

import AppOutline from "../../components/AppOutline";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useBundles } from "../../context/BundlesContext";
import styles from './ViewBundle.module.css';
import { ClipLoader } from "react-spinners";
import { EndpointTypesMap } from "../../EndpointMap";
import ViewMissingBundle from "../ViewMissingBundle";
import CompressProductImages from "./components/CompressProductImages";
import { mkConfig as mkConfigCsv, generateCsv, download as downloadCsv } from "export-to-csv";

type LoaderParams = {
    bundle_id: number;
    workflow_id: number;
}
function isLoaderParams(x: unknown): x is LoaderParams {
    // @ts-expect-error TypeGuard is establishing type 
    return x && typeof x==='object' && x!==null && x.bundle_id!==undefined && x.workflow_id!==undefined;
}
export const loader:LoaderFunction = (data) => {
    if( isLoaderParams(data.params) ) {
        if( typeof data.params.bundle_id==='string' ) data.params.bundle_id = parseInt(data.params.bundle_id);
        if( typeof data.params.workflow_id==='string' ) data.params.workflow_id = parseInt(data.params.workflow_id);
        return data.params;
    } else {
        //debugger;
        throw new Error("Unknown params");
    }
}

// #TYPE_EXPORT
export type BundleWorkflowProduct = EndpointTypesMap['view-bundle-workflow::POST']['response']['products'][number]; 
type LogItem = EndpointTypesMap['view-bundle-log::POST']['response']['log'][number];


type LoaderProps = {loading:boolean};
const Loader:React.FC<LoaderProps> = (props) => {
    return (<ClipLoader size={18} color="#ddd" {...props} />)
}

interface EventItem {
    type?: string;
    event?: string;
    product_id?: string;
    bundle_id?: string;
  }

const ViewBundle:React.FC = () => {
    
    const {bundle_id, workflow_id} = useLoaderData() as LoaderParams;
    const auth = useAuth();
    const bundles = useBundles();
    const [bundleWorkflowProducts, setBundleWorkflowProducts] = useState<BundleWorkflowProduct[]>([]);
    const [bundleWorkflowId, setBundleWorkflowId] = useState<number | undefined>();
    const [viewLogForID, setViewLogForID] = useState<number | undefined>();
    const [viewLog, setViewLog] = useState<LogItem[]>([]); // #TYPE_EXPORT need the log type
    const [dataState, setDataState] = useState<'loading' | 'loaded' | 'not_created' | 'unknown_bundle' | 'unknown_workflow'>('loading');
    
    
    

    const bundleOverview = useMemo(() => bundles.bundles.find(x => x.id===bundle_id), [bundles, bundle_id]);
    /*
    const workflow_id = useMemo(() => {
        for( const bundle of bundles.bundles ) {
            for( const bundleWorkflow of bundle.workflows ) {
                if( bundleWorkflow.bundle_workflow_id===bundle_workflow_id ) {
                    return bundleWorkflow.workflow_id
                }
            }
        }
        return undefined; // TODO #DATA_STITCH
    }, [bundle_workflow_id, bundles]);
    */

    const refresh = useCallback(async () => {
        if( typeof bundle_id==='number' && typeof workflow_id==='number' ) {
            const response = await auth.invokeEdge('view-bundle-workflow::POST', {
                bundle_id,
                workflow_id
            })

            
            if( response.error ) {
                if( response.error.type==='function' ) {
                    if( response.error.data.error?.type==='not_created' ) {
                        return setDataState('not_created');
                    } else if( response.error.data.error?.type==='unknown_bundle' ) {
                        return setDataState('unknown_bundle');
                    } else if( response.error.data.error?.type==='unknown_workflow' ) {
                        return setDataState('unknown_workflow');
                    }
                }
                // TODO Show a UI state for this
                console.warn("ViewBundle bad response", response);
                //debugger;
            } else if( response.data ) {
                setBundleWorkflowId(response.data.bundle_workflow_id);
                setBundleWorkflowProducts(response.data.products);
                setDataState('loaded')
            }
        }

    }, [bundle_id, workflow_id, auth]);

    useEffect(() => {
        refresh();
        const timer = setInterval(() => {
            refresh();
        }, 1000*5);
        return function unload() {
            clearInterval(timer);
        }
    }, [refresh])

    useEffect(() => {
        let active = true;
        setViewLog([]);
        if( typeof bundleWorkflowId==='number' && viewLogForID ) {
            (async () => {
                const response = await auth.invokeEdge('view-bundle-log::POST', {
                    bundle_workflow_id: bundleWorkflowId,
                    product_id: viewLogForID
                });

                if( response.data ) {
                    if( active ) setViewLog(response.data.log);
                } else {
                    console.warn('view-bundle-log bad response', response);
                    //debugger;
                }
            })();
        }

        return function unload() {
            active = false;
        }
    }, [bundleWorkflowId, viewLogForID])


    const onExportCsv = useCallback(() => {

        type Output = {
            id: BundleWorkflowProduct['id'],
            //product_details: string,
            image_details:string,
            variant_of: BundleWorkflowProduct['is_multiple_of'],
            page_title: BundleWorkflowProduct['page_title'],
            meta_description: BundleWorkflowProduct['meta_description'],
            heading: BundleWorkflowProduct['heading'],
            description: BundleWorkflowProduct['description'],
            url_suggestion: BundleWorkflowProduct['url_suggestion'],
            url_suggestion_level: BundleWorkflowProduct['url_suggestion_level'],
        } 
        const output:Output[] = [];
         bundleWorkflowProducts.forEach(x => {
            output.push({
                id: x.id,
                variant_of: x.is_multiple_of,
                page_title: x.page_title,
                meta_description: x.meta_description,
                heading: x.heading, 
                description: x.description + (x.fact_sheet? `\n\n${x.fact_sheet}` : ''),
                url_suggestion: x.url_suggestion,
                url_suggestion_level: x.url_suggestion_level,
                //product_details:JSON.stringify(x.product_details), 
                image_details:JSON.stringify(x.image_details)
            });
        })

        output.sort((a, b) => a.id - b.id);

        const csvConfig = mkConfigCsv({ useKeysAsHeaders: true });

        const csv = generateCsv(csvConfig)(output);
        downloadCsv(csvConfig)(csv)

    }, [bundleWorkflowProducts]);
    
    
    if( dataState==='unknown_bundle' ) {
        return (<ViewMissingBundle />);
    } else {
        return (<AppOutline>
        
            <div className={styles.header}>
                <h1>{bundleOverview?.name}</h1>
                <div className={styles.workflows}>
                    <div className={styles.workflowsTitle}>Workflows:</div>
                    <div className={styles.workflowItems}>
                        {bundles.workflows.map((workflow, idx) => (
                            <div 
                            className={`${styles.workflowItem}`} 
                            key={workflow.id} 
                            title={`Workflow: ${workflow.name}`}
                            data-selected={workflow_id===workflow.id? '1' : '0'}
                            >
                                <span>{idx}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
    
            
            {dataState==='loading'?
            (
                <>Loading...</>
            ) : null}
            {dataState==='not_created'?
            (
                <>
                    <div>
                        The selected AI workflow has not yet been run for this bundle.
                    </div>
                    <div>
                        <button>Start</button>
                    </div>
                </>
            ) : null}
            {dataState==='unknown_workflow'?
            (
                <>
                    <div>
                        Please choose a Workflow from the choices above.
                    </div>
                </>
            ) : null}
    
            {typeof bundleWorkflowId==='number' && dataState==='loaded'?
            (<>
                <div className={styles.productsHeading}>
                    <h2>Products</h2>
                    <div>
                        
                        <button onClick={onExportCsv}>Export CSV/JSON</button>
                    </div>
                </div>
                <table className={styles.product_list}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Page Title</th>
                            <th>Meta Description</th>
                            <th>Heading</th>
                            <th>Description</th>
                            <th>URL</th>
                            <th>URL Suggestion</th>
                            <th>Image Captions</th>
                            <th>Variant</th>
                            <th>...</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bundleWorkflowProducts.map(bwProduct => (
                            <tr key={bwProduct.id} id={bwProduct.id+''}>
                                <td>
                                    {bwProduct.product_details?.product_name?? ''}
                                    <div className={styles.product_details}>
                                        Colour: {bwProduct.product_details?.colour}. Dimensions: {bwProduct.product_details?.dimensions}. Brand: {bwProduct.product_details?.brand}. Range: {bwProduct.product_details?.range}. {bwProduct.product_details?.use_case? `Use case: ${bwProduct.product_details?.use_case}` : ''}
                                    </div>
                                </td>
                                <td>
                                    {bwProduct.page_title} <Loader loading={!bwProduct.page_title}/>
                                </td>
                                <td>
                                    {bwProduct.meta_description} <Loader loading={!bwProduct.meta_description}/>
                                </td>
                                <td>
                                    {bwProduct.heading} <Loader loading={!bwProduct.heading}/>
                                </td>
                                <td>
                                    {bwProduct.description?.split(/\n/).map(x => (<>{x}<br/></>))} <Loader loading={!bwProduct.description}/>
                                    <div className={styles.fact_sheet}>
                                        {bwProduct.fact_sheet??''}
                                    </div>
                                </td>
                                <td>
                                    {bwProduct.url_suggestion || ''}
                                    <div className={styles.product_details}>
                                        <div>
                                            Current: {bwProduct?.product_details?.url || 'NA'}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {bwProduct.url_suggestion_level==='missing'? 'Missing' : ''}
                                    {bwProduct.url_suggestion_level==='order'? 'Reorder' : ''}
                                </td>
                                <td>
                                    {bwProduct.image_details? 
                                    bwProduct.image_details.filter(x => !!x.alt_text).map(x => (
                                        <div key={x.bundle_workflow_products_image_id}>
                                            <div><a href={x.url}>Image</a></div>
                                            <div>{x.alt_text}</div>
                                        </div>
                                    ))
                                    : <Loader loading={true}/> }
                                </td>
                                <td>
                                    {typeof bwProduct.is_multiple_of==='number'? (<a href={`#${bwProduct.is_multiple_of}`}>Yes</a>) : ''}
                                </td>
                                <td className={styles.viewLogAttach}>
                                    <button onClick={() => setViewLogForID(prev => prev===bwProduct.product_id? undefined : bwProduct.product_id)}>!</button>
                                    {viewLogForID===bwProduct.id?
                                    (
                                    <div className={styles.viewLogContainer}>
                                        {viewLog.length===0?
                                        (
                                            <>Nothing to show.</>
                                        )
                                        :
                                        (
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Event</th>
                                                        <th>Details</th>
                                                        <th>Cost</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {viewLog.map(item => (<tr key={item.event??''+item.created_at}>
                                                        <td>{item.event}</td>
                                                        <td>{
                                                        Object.keys(item)
                                                        .filter((key):key is keyof EventItem => !['type', 'event', 'product_id', 'bundle_id'].includes(key))
                                                        .map((key)=> (
                                                            <div key={key}>
                                                                <span>{key}:</span>
                                                                <span>{item[key]}</span>
                                                            </div>
                                                        ))}</td>
                                                        <td>{"cost" in item? item.cost : 0}</td>
                                                        <td>{item.created_at.toISOString()}</td>
                                                    </tr>))}
                                                </tbody>
                                            </table>
                                        )
                                        }
                                    </div>
                                    )
                                    : undefined }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h2>Compress Product Images</h2>
                <CompressProductImages 
                    bundleWorkflowProducts={bundleWorkflowProducts} 
                    bundleWorkflowId={bundleWorkflowId} 
                    bundleId={bundle_id}
                    workflowId={workflow_id}
                    />
            </>)
            : null }
            
        </AppOutline>);
    }

    

        
        
        
    
}
export default ViewBundle;




/*
type ViewLogResponse = {
    logs: {details: Record<string, string>, ai_model?: string, ai_cost?:number, created_at: string}[];
}
type LogI = ViewLogResponse['logs'][number];
*/