import { createBrowserRouter, RouterProvider } from 'react-router-dom';
//import Admin from './pages/Admin';

import ViewBundle, {loader as viewBundleLoader} from './pages/ViewBundle';
import NewBundle from './pages/NewBundle';
import App from './pages/App';
import ViewMissingBundle from './pages/ViewMissingBundle';


/*
Best practice: 
- Create a ./pages folder
- Add directories to ./pages with an index.tsx 
- Import them here, and reference them as an 'element'
*/

const router = createBrowserRouter([
    {
      path: "/",
      element: <App />
    },
    {
        path: "/new-bundle",
        element: <NewBundle />
    },
    {
        path: "/view-bundle/:bundle_id?/:workflow_id?",
        element: <ViewBundle />,
        loader: viewBundleLoader,
        errorElement: <ViewMissingBundle />
    }
]);



const RouterWithPages:React.FC = () => {
    return (<RouterProvider router={router} />);
}
export default RouterWithPages;